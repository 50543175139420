import React, {useState, useEffect, createRef} from "react";
import {Image} from "semantic-ui-react";
import BackgroundImage from "../images/BackgroundImageG.jpg"
import Header from "./Header";
import Body from "./Body";

export default function Main() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div style={{color: "white", fontFamily: "Times New Roman, serif"}}>
            <Image className='BackgroundImageFullScreen' src={BackgroundImage}/>
            <Header/>
            <Body/>

        </div>
    );
};
