import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button} from "semantic-ui-react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";

export default function DialogRedux() {
    const show_dialog = useSelector(state => state.dialog.show_dialog);
    const function_for_dialog = useSelector(state => state.dialog.function_for_dialog);
    const dialog_title = useSelector(state => state.dialog.dialog_title);
    const custom_component = useSelector(state => state.dialog.custom_component);
    const dispatch = useDispatch();

    return show_dialog && <Dialog
        style={{margin: '5%'}}
        maxWidth={false}
        open={show_dialog}
        onClose={() => dispatch({type: 'dialog/close_dialog'})}
    >
        {
            custom_component ?
                custom_component :
                <div>
                    <DialogTitle id="alert-dialog-title"
                                 style={{textAlign: "center"}}>{dialog_title ? dialog_title : 'Подтвердите удаление'}</DialogTitle>
                    <DialogActions className={'use_semantic'}>
                        <Button
                            size={"tiny"}
                            onClick={() => {
                                dispatch({type: 'dialog/close_dialog'})
                            }}
                        >
                            Отмена
                        </Button>
                        <div style={{marginLeft: 20}}>
                            <Button
                                autoFocus
                                size={"tiny"}
                                onClick={() => {
                                    function_for_dialog();
                                    dispatch({type: 'dialog/close_dialog'})
                                }}
                            >
                                Подтвердить
                            </Button>
                        </div>
                    </DialogActions>
                </div>
        }
    </Dialog>
}
