import React, {useEffect, useState} from "react";
import Border1 from "../images/border1.png";
import {Image, Popup} from "semantic-ui-react";
import anime from 'animejs/lib/anime.es.js';
import {useSelector} from "react-redux";
import PhotoLine from "../components/PhotoLine/PhotoLine";
import video_home_1 from "../video/video_home_1.mp4"
import video_home_2 from "../video/video_home_2.mp4"
import video_home_1_poster from "../video/video_home_1_poster.jpg"
import video_home_2_poster from "../video/video_home_2_poster.jpg"
import About_us from "./About_us";
import Music_and_lyrics from "./Music_and_lyrics";
import {isUserUsingMobile, rotate_crossbrowser} from "../helpers/commonHelpers";
import Photo_and_video from "./Photo_and_video";
import Concerts from "./Concerts";
import Merch from "./Merch";


const min_body_width = 550;
const border_width = '9em';

export default function Body() {
    const [title, set_Title] = useState(useSelector(state => state.current_page.title));
    const new_title = useSelector(state => state.current_page.title);

    //animation
    if (new_title !== title) {
        anime.timeline({loop: false})
            .add({
                targets: '.disappear_and_appear',
                opacity: 0,
                duration: 1500,
                easing: "easeOutExpo",
                delay: 0
            });
        setTimeout(() => {
            set_Title(new_title);
            anime.timeline({loop: false})
                .add({
                    targets: '.disappear_and_appear',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 1500,
                    delay: 0
                });
        }, 750)
    }
    //

    return <div
        style={{display: "flex", justifyContent: "center", marginLeft: '1%', marginRight: '1%', paddingBottom: 50}}
        className='disappear_and_appear'>
        <div style={{
            display: "flex",
            marginTop: 22,
            maxWidth: 1600,
            minWidth: min_body_width
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: -60,
                userSelect: 'none'
            }}>
                <div style={{width: border_width}}><Image src={Border1}/></div>
                <div style={{width: border_width, ...rotate_crossbrowser(270)}}><Image src={Border1}/>
                </div>
            </div>
            <div style={{userSelect: "none"}}>

                <div style={{fontSize: 24, marginTop: 8, textAlign: "center"}}
                     className={`PalatinoFont`}
                >
                    {title}
                </div>

                <div className='PalatinoFont Body_inner_container'>
                    {show_content(title)}
                </div>

            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginLeft: -80,
                userSelect: 'none'
            }}>
                <div style={{width: border_width, ...rotate_crossbrowser(90)}}><Image src={Border1}/></div>
                <div style={{width: border_width, ...rotate_crossbrowser(180)}}><Image src={Border1}/></div>
            </div>
        </div>
    </div>
}

function show_content(title) {
    switch (title) {
        case 'Добро пожаловать!':
            return <About_us/>;
        case 'О нас':
            return <About_us/>;
        case 'Музыка и тексты':
            return <Music_and_lyrics/>;
        case 'Фото и Видео':
            return <Photo_and_video/>;
        case 'Концерты':
            return <Concerts/>;
        case 'Мерч':
            return <Merch/>;
    }
}
