import React, {useState} from "react";
import {Image, Popup} from "semantic-ui-react";
import mlf from "../images/concerts/mlf.jpg";
import Link from "@mui/material/Link";


export default function Concerts() {
    return <div>

        <div style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            {/*<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>*/}
            {/*    <video*/}
            {/*        controls*/}
            {/*        preload="auto"*/}
            {/*        width={'50%'}*/}
            {/*        autoPlay={true}*/}
            {/*        // poster={video_home_2_poster}*/}
            {/*    >*/}
            {/*        <source src={halloween} type="video/mp4"/>*/}
            {/*        <p className="vjs-no-js">*/}
            {/*            Для просмотра этого видео включите выполнение JavaScript или обновите браузер*/}
            {/*        </p>*/}
            {/*    </video>*/}
            {/*    <div style={{marginTop: 40}}>*/}
            {/*        <div>DARK STORY OF HALLOWEEN</div>*/}
            {/*        <pre style={{maxWidth: 600, textWrap: 'wrap', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word'}} className={'PalatinoFont'}>{"🔥Стартуем!🔥\n" +*/}
            {/*        "Друзья, объявляем старт продажи билетов!\n" +*/}
            {/*        "\n" +*/}
            {/*        "⏳29 октября, 18:00\n" +*/}
            {/*        "\n" +*/}
            {/*        "🌍Клуб IZI\n" +*/}
            {/*        "Москва, Басманный переулок, дом 8, строение 1\n" +*/}
            {/*        "\n" +*/}
            {/*        "Этим вечером для вас выступят:\n" +*/}
            {/*        "\n" +*/}
            {/*        "🎃 IRRAKRA\n" +*/}
            {/*        "LED-Show Metal\n" +*/}
            {/*        "\n" +*/}
            {/*        "🎃 ONE MORE TALE\n" +*/}
            {/*        "Symphonic Metal\n" +*/}
            {/*        "\n" +*/}
            {/*        "🎃 Divided Island\n" +*/}
            {/*        "UltraViolet Rock\n" +*/}
            {/*        "\n" +*/}
            {/*        "🎃 CYBER HUNTERZ\n" +*/}
            {/*        "Neuropunk, Psystyle, Dancecore, Hardstyle, Brakes, Cinematic Rave\n" +*/}
            {/*        "\n" +*/}
            {/*        "И на этом список участников шоу не заканчивается! Также в программе вечера яркие ведущие, обворожительные танцовщицы и многие другие.\n" +*/}
            {/*        "\n" +*/}
            {/*        "✅В зале имеется несколько вариантов размещения гостей.\n" +*/}
            {/*        "\n" +*/}
            {/*        "ТАНЦПОЛ (1 этаж) — бары, фотозона, танцевальная площадка, где можно отлично провести время активной компанией.\n" +*/}
            {/*        "\n" +*/}
            {/*        "VIP (2 этаж) — здесь можно уютно разместиться за столиками. Также имеется бар. (с возможностью выхода на танцпол)\n" +*/}
            {/*        "\n" +*/}
            {/*        "LUX — приватная зона на втором этаже сбоку, подальше от чужих глаз, откуда открывается отличный вид на сцену. Билеты можно заказать только в предпродаже. Количество мест ограничено. Вход строго 12+. За покупку билета на два лица в зону LUX — ПОДАРОК!\n" +*/}
            {/*        "Также для обладателей билетов категории LUX бесплатная беспроигрышная лотерея \"Древо Хэллоуина\".\n" +*/}
            {/*        "\n" +*/}
            {/*        "Дети младше 12 лет проходят на мероприятие ⚠БЕСПЛАТНО⚠ (строго в сопровождении взрослых).\n" +*/}
            {/*        "\n" +*/}
            {/*        "📕Также доступно бронирование столов. Об этом - в следующих публикациях.\n" +*/}
            {/*        "Следите за новостями🤘"}</pre>*/}

            {/*        <Link href={"https://one-more-tale.timepad.ru/event/2465819/"} color="inherit" underline="none"*/}
            {/*              style={{display: "flex", justifyContent: "center"}}>*/}
            {/*            БИЛЕТЫ ТУТ!*/}
            {/*        </Link>*/}


            {/*    </div>*/}
            {/*</div>*/}

            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{marginTop: 20}}>
                    <Image src={mlf} size={'huge'}/>
                    {/*<div style={{marginTop: 40, textAlign: 'center', fontSize: 26}}></div>*/}
                    <pre style={{
                        maxWidth: 600,
                        marginLeft: 40,
                        textWrap: 'wrap',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word'
                    }} className={'PalatinoFont'}>{"Неоновые создания спешат сообщить новость о том, что продажа физических и электронных билетов открыта!!!\n" +
                    "\n" +
                    "Старт продаж билетов на фестиваль Metal for Life 2024 - открыт!\n" +
                    "\n" +
                    "Не упустите возможность получить уникальные призы и увидеть на сцене клуба C.20 группы:\n" +
                    "\n" +
                    "BAGIRA\n" +
                    "BUTTERFLY TEMPLE\n" +
                    "IRRAKRA\n" +
                    "PSylociD | Official group\n" +
                    "ГРОБОВАЯ ДОСКА\n" +
                    "\n" +
                    "Напоминаем, заработанные фестивалем средства пойдут на поддержку детей с тяжелыми заболеваниями, подопечных Благотворительный фонд имени Арины Тубис\n" +
                    "\n" +
                    "Приобрести электронный билет вы можете по ссылке:\n" +
                    "https://fond-arina-tubis.timepad.ru/event/2941501/\n" +
                    "\n" +
                    "Приобрести физические билеты вы можете у наших партнеров:\n" +
                    "Парикмахерская Hairs Boom | Петрозаводск или позвонив по номеру +7 (900) 460-34-01 -торопитесь, количество физических билетов ограничено!\n" +
                    "\n" +
                    "На данный момент стоимость билета 1000 р., успейте приобрести до повышения цены\n" +
                    "\n" +
                    "Среди обладателей билетов также будут розыграны призы и подарки от организаторов фестиваля!\n" +
                    "\n" +
                    "- Стол с депозитом 5000₽\n" +
                    "- Пакет подарков от организаторов\n" +
                    "- Проход на саундчек, фотосессия и автографы групп\n" +
                    "\n" +
                    "Для участия в розыгрыше проверьте подписку на сообщество!\n" +
                    "Торопись, чтобы быть среди первых обладателей билетов и участвовать в розыгрыше невероятных призов!\n" +
                    "\n" +
                    "Жми на кнопку внизу поста, чтобы успеть забрать свой билет!\n" +
                    "\n" +
                    "Приди на Metal For Life, подари надежду!"}</pre>
                </div>
                <Link href={"https://vk.com/metalforlifeptz"} color="inherit" underline="none"
                      style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                    Мероприятие тут!
                </Link>
                <Link href={"https://fond-arina-tubis.timepad.ru/event/2941501/"} color="inherit" underline="none"
                      style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                    Билеты тут!
                </Link>
            </div>

        </div>

    </div>
}
