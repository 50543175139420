import React from "react";
import Link from "@mui/material/Link";
import {Icon, Popup} from "semantic-ui-react";

export default function PopupWithLink({popup_text, link_to, icon_name, size = 'large', custom_icon}) {
    return <div>
        <Popup content={popup_text}
               className={"Bahnfont_light"}
               position={"top center"}
               trigger={
                   <Link href={link_to} color="inherit" underline="none">
                       {
                           custom_icon ? custom_icon :
                               <Icon link size={size} name={icon_name}/>
                       }
                   </Link>
               }
        />
    </div>
}
