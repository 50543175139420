import React from "react";
import PhotoLine from "../components/PhotoLine/PhotoLine";
import video_home_1_poster from "../video/video_home_1_poster.jpg";
import video_home_1 from "../video/video_home_1.mp4";
import video_home_2_poster from "../video/video_home_2_poster.jpg";
import video_home_2 from "../video/video_home_2.mp4";
import irrakra_poster from "../video/irrakra_poster.JPG";
import video_by_IRRAKRA from "../video/video_by_IRRAKRA.mp4";
import {Popup} from "semantic-ui-react";


export default function About_us() {
    return <div>

        <PhotoLine/>

        <div style={{marginTop: 40, display: "flex", justifyContent: "space-around"}}>
            <video
                controls
                preload="auto"
                width={'25%'}
                poster={video_home_1_poster}
            >
                <source src={video_home_1} type="video/mp4"/>
                <p className="vjs-no-js">
                    Для просмотра этого видео включите выполнение JavaScript или обновите браузер
                </p>
            </video>
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 20,
                marginRight: 20,
                marginTop: 22
            }}>
                <div>LED - шоу под тяжелые риффы</div>
                <div>Неоновые монстры в твоем городе</div>
                <div>Мы расскажем обо всех людских пороках</div>
                <div>Прошлых, настоящих и будущих</div>
                <div>Дата образования: в недалеком будущем</div>
            </div>
            <video
                controls
                preload="auto"
                width={'25%'}
                poster={video_home_2_poster}
            >
                <source src={video_home_2} type="video/mp4"/>
                <p className="vjs-no-js">
                    Для просмотра этого видео включите выполнение JavaScript или обновите браузер
                </p>
            </video>
        </div>

        <div style={{display: "flex", justifyContent: "center", marginTop: 20, color: "white"}}>
            <video
                controls
                preload="auto"
                width={'100%'}
                poster={irrakra_poster}
            >
                <source src={video_by_IRRAKRA} type="video/mp4"/>
                <p className="vjs-no-js">
                    Для просмотра этого видео включите выполнение JavaScript или обновите браузер
                </p>
            </video>
        </div>

        <div style={{display: "flex", justifyContent: "center", marginTop: 20, color: "white"}}>
            <Popup content={"По всем вопросам"}
                   className={"Bahnfont_light"}
                   position={"top center"}
                   trigger={
                       <div
                           className={`glitch cursor_pointer`} style={{width: 220}}
                           onClick={() => window.location.assign("https://vk.com/id18853339")}
                           data-text="Связаться с Хищником">Связаться с Хищником
                       </div>
                   }
            />
        </div>

    </div>
}
