import {createSlice, configureStore} from '@reduxjs/toolkit'
import {useSelector} from "react-redux";

/**    reducers:    */
const userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('userName'),
        userRole: localStorage.getItem('userRole')
    },
    reducers: {
        set_userId: (state, action) => {
            state.userId = action.payload
        },
        set_userName: (state, action) => {
            state.userName = action.payload
        },
        set_userRole: (state, action) => {
            state.userRole = action.payload
        }
    }
});

const current_pageSlice = createSlice({
    name: 'current_page',
    initialState: {
        title: 'Добро пожаловать!'
    },
    reducers: {
        set_title: (state, action) => {
            state.title = action.payload
        }
    }
});

const snackBarSlice = createSlice({
    name: 'snackBar',
    initialState: {
        snackBarSuccessMessage: undefined,
        snackBarErrorMessage: undefined,
        snackBarSuccess: undefined,
        snackBarError: undefined
    },
    reducers: {
        snackBarErrorMessage: (state, action) => {
            state.snackBarErrorMessage = action.payload;
            state.snackBarError = true
        },
        snackBarSuccessMessage: (state, action) => {
            state.snackBarSuccessMessage = action.payload;
            state.snackBarSuccess = true
        },
        snackBarCloseErrorMessage: (state) => {
            state.snackBarError = false
        },
        snackBarCloseSuccessMessage: (state) => {
            state.snackBarSuccess = false
        }
    }
});

const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        count: 0,
    },
    reducers: {
        loading_on: (state, action) => {
            state.count = state.count + 1
        },
        loading_off: (state, action) => {
            state.count = state.count - 1
        },
    }
});

const dialogSlice = createSlice({
    name: 'dialog',
    initialState: {
        show_dialog: false,
        function_for_dialog: undefined,
        dialog_title: undefined,
        custom_component: undefined
    },
    reducers: {
        show_dialog: (state, action) => {
            state.function_for_dialog = action.payload.function_for_dialog;
            state.dialog_title = action.payload.dialog_title;
            state.custom_component = action.payload.custom_component;
            state.show_dialog = true
        },
        close_dialog: ((state, action) => {
            state.show_dialog = false;
            state.function_for_dialog = undefined;
            state.dialog_title = undefined;
            state.custom_component = undefined;
        })
    }
});


export const {set_userId, set_userName, set_userRole} = userSlice.actions;
export const {set_title} = current_pageSlice.actions;
export const {snackBarErrorMessage, snackBarSuccessMessage, snackBarCloseErrorMessage, snackBarCloseSuccessMessage} = snackBarSlice.actions;
export const {loading_on, loading_off} = loadingSlice.actions;
export const {show_dialog, close_dialog} = dialogSlice.actions;

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        current_page: current_pageSlice.reducer,
        snackBar: snackBarSlice.reducer,
        loading: loadingSlice.reducer,
        dialog: dialogSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                // ignoredActions: ['your/action/type'],
                // Ignore these field paths in all actions
                ignoredActionPaths: [
                    'payload.function_for_dialog',
                ],
                // // Ignore these paths in the state
                ignoredPaths: ['dialog.function_for_dialog'],
            },
        }),
});
