import React, {useState} from "react";
import {Gallery} from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import video_home_2_poster from "../video/video_home_2_poster.jpg";
import video_home_2 from "../video/video_home_2.mp4";


export const images = [];
let n = 1;
while (n < 123) {
    const imported_item = require(`../images/photo_gallery/${n}.jpg`);
    if (imported_item) {
        images.push({
            src: imported_item,
            original: imported_item,
            // tags: [
            //     { value: "IRRAKRA", title: "IRRAKRA" },
            // ],
            caption: "IRRAKRA",
        });
    }
    n++;
}


export default function Photo_and_video() {
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <div>
            <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
            />
            {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}

            <div>
                <iframe src="https://www.youtube.com/embed/CW1z5-yrsZE"
                        title="IRRAKRA &quot;Игра&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                <iframe src="https://www.youtube.com/embed/uOK2L0tincI"
                        title="IRRAKRA &quot;Секс&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                <iframe src="https://www.youtube.com/embed/QJa_ktlaybU"
                        title="IRRAKRA &quot;Фея&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                <iframe src="https://www.youtube.com/embed/ydzVjFmaQM4"
                        title="IRRAKRA &quot;Дракула&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                <iframe src="https://www.youtube.com/embed/4AUXWhGdHb4"
                        title="IRRAKRA &quot;Берсерк&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                <iframe src="https://www.youtube.com/embed/T3rQ1tBe-iA"
                        title="IRRAKRA &quot;Всадники&quot;" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
            </div>
        </div>
    );
}
