import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./redux/store";

document.title = 'IRRAKRA Официальный сайт';

const rootElement = document.getElementById("root");
createRoot(rootElement).render(<Provider store={store}>
    <App/>
</Provider>);
