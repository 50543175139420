import {useDispatch, useSelector} from "react-redux";
import "./LoadingIndicatorRedux.css"
import React from "react";
import {Icon} from "semantic-ui-react";

export default function LoadingIndicatorRedux() {
    const count = useSelector(state => state.loading.count);

    return <div className="LoadingIndicatorRedux_container" style={count > 0 ? {} : {display: 'none'}}>
        <Icon loading name={'cog'} size='large'/>
        <div className={'Bahnfont_bold'}>обновление</div>
    </div>
}
