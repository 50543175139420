//fixed
import iskr_1 from './iskr_1.png'
import iskr_2 from './iskr_2.png'
import iskr_3 from './iskr_3.png'

var onMoveNum = 10;
var no = 100;											// Кол-во искр, привязанных к курсор
var iskr = [iskr_1, iskr_2, iskr_3];
var cliskr = 50;										// Кол-во искр, вылетающих при нажатии кнопки мыши
var speed = 40;											// Какая-то скорость (configure interval btw flash (1000=1 second)), вероятно время между кадрами, в мс
var g = 0.8;											// Ускорение свободного падения (округленное)
// Какие-то как-бы константы, идентифицирующие текущую страницу
var isNetscape = navigator.appName == "Netscape";
var isOpera = navigator.appName == "Opera";
var isOldAndBuggy = (navigator.userAgent.indexOf("MSIE") >= 0 && navigator.userAgent.indexOf("Trident") < 0);
var doc_width, doc_height;
var animated = false;
// �?спользуемые переменные/массивы
var xcoord = 0;									// текущая координата курсора
var ycoord = 0;									// текущая координата курсора
var xd = [];								// координаты искр
var yd = new Array();								// координаты искр
var stx = new Array();								// координата отсчета движения искры
var sty = new Array();								// координата отсчета движения искры
var dd = new Array();								// активность/неактивность искры
var vxd = new Array();								// скорость (уравнение) движения искры
var vyd = new Array();								// скорость (уравнение) движения искры
var grEWxd = new Array();							// граница движения искры - восток, запад
var grNyd = new Array();							// граница движения искры - север
var grSyd = new Array();							// граница движения искры - юг
var dots = new Array();							// массив всех искр, привязанных к курсору
var imgs = new Array();							// служебный массив, обозначающий собственно сами картинки искр
var imgs0 = new Array();							// служебный массив, обозначающий собственно сами картинки искр первой позиции
var imgs1 = new Array();							// служебный массив, обозначающий собственно сами картинки искр второй позиции
var imgs2 = new Array();							// служебный массив, обозначающий собственно сами картинки искр третьей позиции
var imgPreload = new Image();
var workerThread = false;
var suspend = false;
var freeDots = new Array();
var count = new Date();
var previousLaunch = count.getTime();
var curMouseX = 0;
var curMouseY = 0;
var prevMouseX = 0;
var prevMouseY = 0;

// Цикл определения/идентификации каждой искры (установка начальных значений)
function iterate2() {
    const div_of_allow_space = document.createElement("div");
    div_of_allow_space.id = 'allow_space_div';
    div_of_allow_space.setAttribute("style", `overflow: hidden; position: absolute;top: 0; left: 0;width: ${document.body.scrollWidth}px; height: ${document.body.scrollHeight}px;pointer-events: none;`);
    for (var i = 0; i < no; ++i) {
        dots[i] = document.createElement("div");
//imgs[i] = document.createElement("img");
        imgs0[i] = document.createElement("img");
        imgs1[i] = document.createElement("img");
        imgs2[i] = document.createElement("img");
        /*imgs0[i] = document.createElement("div");
        imgs1[i] = document.createElement("div");
        imgs2[i] = document.createElement("div");*/
//dots[i].appendChild(imgs[i]);
        dots[i].appendChild(imgs0[i]);
        dots[i].appendChild(imgs1[i]);
        dots[i].appendChild(imgs2[i]);
        div_of_allow_space.appendChild(dots[i]);
        dots[i].id = 'dot' + i;


        dots[i].setAttribute("style", 'position: absolute;z-index:99999;opacity: 100;overflow: hidden;');
        dots[i].setAttribute("onselectstart", 'return false;');
        dots[i].setAttribute("ondragstart", 'return false;');

//imgs[i].id='iskr'+i;
//imgs[i].class='iskr'+i;
//imgs0[i].class='iskr'+i;
//imgs1[i].class='iskr'+i;
//imgs2[i].class='iskr'+i;
        imgs0[i].id = 'iskr0-' + i;
        imgs1[i].id = 'iskr1-' + i;
        imgs2[i].id = 'iskr2-' + i;
//imgs[i].src = iskr[0];

        /*imgs0[i].setAttribute("style", "opacity: 100; background: url("+iskr[0]+") no-repeat; width: 4px; height: 4px");
        imgs1[i].setAttribute("style", "opacity: 100; background: url("+iskr[1]+") no-repeat; width: 7px; height: 7px");
        imgs2[i].setAttribute("style", "opacity: 100; background: url("+iskr[2]+") no-repeat; width: 10px; height: 10px");*/

        imgs0[i].setAttribute("onselectstart", 'return false;');
        imgs0[i].setAttribute("ondragstart", 'return false;');
        imgs1[i].setAttribute("onselectstart", 'return false;');
        imgs1[i].setAttribute("ondragstart", 'return false;');
        imgs2[i].setAttribute("onselectstart", 'return false;');
        imgs2[i].setAttribute("ondragstart", 'return false;');

        imgs0[i].src = iskr[0];
        imgs1[i].src = iskr[1];
        imgs2[i].src = iskr[2];
        imgs0[i].style.display = "block";
        imgs1[i].style.display = "none";
        imgs2[i].style.display = "none";
        xd[i] = 0;
        yd[i] = 0;
        stx[i] = 0;
        sty[i] = 0;
        dd[i] = 0;
        vxd[i] = 10;
        vyd[i] = 10;
        grEWxd[i] = 0;
        grNyd[i] = 0;
        grSyd[i] = 0;

        freeDots[i] = i;
    }
// get size of window
    if (isNetscape) {
        doc_width = window.innerWidth + window.pageXOffset;
        doc_height = window.innerHeight + window.pageYOffset;
    }
    /*else if (isOpera) {
    doc_width = document.body.scrollLeft;
    doc_height = document.body.scrollTop;
    } */
    else {
        doc_width = document.body.clientWidth + document.body.scrollLeft;
        doc_height = document.body.clientHeight + document.body.scrollTop;
    }

    document.body.appendChild(div_of_allow_space);
}

// Функция возвращает случайным образом: '1' или '-1'

/*	function RndMinus () {
var k = -1;
for (var j = 0; j < 10*Math.random(); j++) {
k = (-1)*k;
}
return k;
}*/

// Движение по координате абцисс, возвращает перемещение по горизонтали
function ddx(inum) {
    var dx = 0;
    dx = vxd[inum];
    return dx;
}

// Движение по координате ординат, возвращает перемещение по вертикали
function ddy(inum) {
    var dy = 0;
    dy = vyd[inum] + g * g / 2;
    vyd[inum] = vyd[inum] + g;
    return dy;
}

// Функция оределения возможности движения искры (отслеживает достижение границы области перемещения искры)
// Возвращает:
//	'1' - искра должна двигаться далее
//	'0' - искра должна гаснуть
function BVerker(bnum, bdx, bdy) {
    if (((((stx[bnum] - xd[bnum]) < grEWxd[bnum]) && (((stx[bnum] - xd[bnum]) >= 0))) || (((xd[bnum] - stx[bnum]) < grEWxd[bnum]) && (((xd[bnum] - stx[bnum]) >= 0)))) && ((((sty[bnum] - yd[bnum]) < grNyd[bnum]) && (((sty[bnum] - yd[bnum]) >= 0))) || (((yd[bnum] - sty[bnum]) < grSyd[bnum]) && (((yd[bnum] - sty[bnum]) >= 0))))) {
        if (((xd[bnum] + bdx) < doc_width) && ((xd[bnum] + bdx) >= 0)) {
            if ((yd[bnum] + bdy) < doc_height) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

// � асчет координат искры и её собственные изменения, при движении курсора
function CurVerker(inum) {
// расчет предполагаемых перемещений ("физический")
    var dx = ddx(inum);
    var dy = ddy(inum);
// расчет перемещений с учетом границ движения
    if (BVerker(inum, dx, dy) == 1) {				// Если искра не вышла за определенную границу
        xd[inum] = xd[inum] + dx;
        yd[inum] = yd[inum] + dy;
// при удалении на половину допустимого расстояния "уменьшить" яркость искры
        if ((((stx[inum] - xd[inum]) > (3 * grEWxd[inum] / 4)) || ((xd[inum] - stx[inum]) > (3 * grEWxd[inum] / 4))) || (((yd[inum] - sty[inum]) > (grSyd[inum] / 2)))) {
            setImage(inum, 0);

        }
    } else {								// Если искра "вышла" за допустимую границу, погасить её
        freeDots[freeDots.length] = inum;
        dd[inum] = 0;
        dots[inum].style.display = "none";
    }
}

// � асчет координат искры и её собственные изменения, при нажатии кнопки мыши
function CliVerker(inum) {
// расчет предполагаемых перемещений ("физический")
    var dx = ddx(inum);
    var dy = ddy(inum);
// расчет перемещений с учетом границ движения
    if (BVerker(inum, dx, dy) == 1) {				// Если искра не вышла/выйдет за определенную границу
        xd[inum] = xd[inum] + dx;
        yd[inum] = yd[inum] + dy;
// при удалении на половину допустимого расстояния "уменьшить" яркость искры
        if ((((stx[inum] - xd[inum]) > (3 * grEWxd[inum] / 4)) || ((xd[inum] - stx[inum]) > (3 * grEWxd[inum] / 4))) || (((yd[inum] - sty[inum]) > (grSyd[inum] / 2)))) {
            setImage(inum, 0);
        }
    } else {								// Если искра "вышла" за допустимую границу, погасить её
        freeDots[freeDots.length] = inum;
        dd[inum] = 0;
        dots[inum].style.display = "none";
    }
}

// Функция движения искры (рассчитывает новые координаты исры)
function Verker(inum) {
    if (dd[inum] == 1) {						// При движении курсора
        CurVerker(inum);
    } else if (dd[inum] == 2) {					// При нажатии кнопки мыши
        CliVerker(inum);
    } else if (dd[inum] == 0) {					// Незадействованные искры
        xd[inum] = xcoord;
        yd[inum] = ycoord;
    }
}

function followmouse(e) {
    document.getElementById("allow_space_div").setAttribute("style", `overflow: hidden; position: absolute;top: 0; left: 0;width: ${document.body.scrollWidth}px; height: ${document.body.scrollHeight}px;pointer-events: none;`);

    if (isNetscape) {
        xcoord = e.pageX;
        ycoord = e.pageY;
    } else if (isOpera) {
        xcoord = window.event.x + window.pageXOffset;
        ycoord = window.event.y + window.pageYOffset;

    } else {
        xcoord = window.event.x + document.body.scrollLeft;
        ycoord = window.event.y + document.body.scrollTop;
    }
    curMouseX = xcoord;
    curMouseY = ycoord;
    if (suspend == true) {
//console.log("resume");
        suspend = false;
        workerThread = setInterval(function () {
            animate()
        }, speed);
    }
}

// Функция, отслеживающая передвижение мыши, определяет координаты курсора и состояния искр при перемещении курсора
function followmouse_animate() {
    let i = freeDots[freeDots.length - 1];
//console.log(i);
//for (var i = 0; i < no; ++i) {
    if (dd[i] == 0) {						// При неактивной искре, сделать движимой
        dd[i] = 1;					// искра занята (в движении)
        freeDots.pop();
        xd[i] = curMouseX;					// установить искру в курсор
        yd[i] = curMouseY;					// установить искру в курсор
        stx[i] = curMouseX;				// запомнить координату отсчета для искры
        sty[i] = curMouseY;				// запомнить координату отсчета для искры
        vxd[i] = 20 * Math.random() - 10;	// установить скорость (уравнение) движения
        vyd[i] = 16 * Math.random() - 8;	// установить скорость (уравнение) движения
        grEWxd[i] = 256 * Math.random();		// установить границу движения искры - восток, запад
        grNyd[i] = 128 * Math.random();		// установить границу движения искры - север
        grSyd[i] = 512 * Math.random();		// установить границу движения искры - юг
// �?зменение характеристик искры
        dots[i].style.display = "block";
        setImage(i, Math.floor(Math.random() * (iskr.length)));
// Выход из цикла
//break;
    }
//}
}

// Функция, отслеживающая нажатия кнопок мыши, определяет состояния искры после нажатия
function mouseclick(e) {
    if (suspend == true) {
//console.log("resume");
        suspend = false;
        workerThread = setInterval(function () {
            animate()
        }, speed);
    }
    var j = 1;
    if (isNetscape) {
        xcoord = e.pageX;
        ycoord = e.pageY;
    } else if (isOpera) {
        xcoord = window.event.x + window.pageXOffset;
        ycoord = window.event.y + window.pageYOffset;

    } else {
        xcoord = window.event.x + document.body.scrollLeft;
        ycoord = window.event.y + document.body.scrollTop;
    }
    for (var i = freeDots.length - 1; i >= 0; i--) {
        if (dd[i] == 0) {							// При неактивной искре, сделать движимой
            dd[i] = 2;						// искра занята (в движении)
            freeDots.pop();
            xd[i] = xcoord + 3;					// установить искру в курсор
            yd[i] = ycoord + 3;					// установить искру в курсор
            stx[i] = xcoord;					// запомнить координату отсчета для искры
            sty[i] = ycoord;					// запомнить координату отсчета для искры
            vxd[i] = 20 * Math.random() - 10;		// установить скорость (уравнение) движения
            vyd[i] = 32 * Math.random() - 16;		// установить скорость (уравнение) движения
            grEWxd[i] = 512 * Math.random();			// установить границу движения искры - восток, запад
            grNyd[i] = 256 * Math.random();			// установить границу движения искры - север
            grSyd[i] = 512 * Math.random();			// установить границу движения искры - юг
// �?зменение характеристик искры
            dots[i].style.display = "block";
            setImage(i, Math.floor(Math.random() * (iskr.length)));
// Увеличение счетчика искр при клике
            j = j + 1;
// Выход из цикла
            if (j > cliskr) {
                break;
            }
        }
    }
}

// Главная функция движения (перемещает все искры)
function animate() {
    if (Math.abs(curMouseX - prevMouseX) > 5 || Math.abs(curMouseY - prevMouseY) > 5) {
        for (var i = 0; i < onMoveNum; i++)
            followmouse_animate();
        prevMouseX = curMouseX;
        prevMouseY = curMouseY;

    }


    animated = true;
//console.log(dd.indexOf(0));
    var contin = false;
//console.log(freeDots.length);
    if (suspend != true && freeDots.length < no) {
        contin = true;
    }
//alert("hey?");
    if (contin == true) {

// Перерасчет границ документа (вычитаем '6' для учета размеров искр (10*10, с центром '-4'))
        if (isNetscape) {
            doc_width = window.innerWidth + window.pageXOffset - 10;
            doc_height = window.innerHeight + window.pageYOffset - 10;
        } else {
            doc_width = document.body.clientWidth + document.body.scrollLeft - 10;
            doc_height = document.body.clientHeight + document.body.scrollTop - 10;
        }
// � ассчет и перемещение каждой искры
        for (var i = 0; i < no; ++i) {
            Verker(i);
            if (dots[i].style.left != (xd[i]) + "px")
                dots[i].style.left = (xd[i]) + "px";
            if (dots[i].style.top != (yd[i]) + "px")
                dots[i].style.top = (yd[i]) + "px";

        }
    } else {
        clearInterval(workerThread);
        workerThread = false;
        suspend = true;
//alert("suspend");
//console.log("suspend");
    }
// Следующий такт
//setTimeout(function(){animate();}, speed);
}

function get_cookie(cookie_name) {
    var results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
    if (results)
        return (unescape(results[2]));
    else
        return null;
}

function toggle_effects() {
    var current_date = new Date();
    var value;
    var name = "effects";
    if (get_cookie("effects") != "off") {
        current_date.setFullYear(current_date.getFullYear() + 10);
        value = "off";
    } else {
        current_date.setFullYear(current_date.getFullYear());
        value = "on";
    }
    var expires = "; expires=" + current_date.toGMTString();
    document.cookie = name + "=" + value + expires + "; path=/";
    if (!isOldAndBuggy) {
        if (get_cookie("effects") != "off") {
            document.onmousemove = followmouse;				// Установка ответа на событие перемещения курсора мыши
            document.onclick = mouseclick;					// Установка ответа на событие нажатия кнопки мыши
            if (animated == false) {
                workerThread = setInterval(function () {
                    animate()
                }, speed);							// Запуск анимационного движения искр
            }
        } else {
            document.onmousemove = null;					// Установка ответа на событие перемещения курсора мыши
            document.onclick = null;						// Установка ответа на событие нажатия кнопки мыши
            clearInterval(workerThread);
            workerThread = false;
            animated = false;
            for (var i = 0; i < no; ++i) {
                if (dd[i] != 0) {
                    freeDots[freeDots.length] = i;
                    dd[i] = 0;
                    dots[i].style.display = "none";
                }
            }

        }
    }
}

function iterate() {
    iterate2();
    if (get_cookie("effects") != "off") {
        document.onmousemove = followmouse;				// Установка ответа на событие перемещения курсора мыши
        document.onclick = mouseclick;					// Установка ответа на событие нажатия кнопки мыши
        workerThread = setInterval(function () {
            animate()
        }, speed);								// Запуск анимационного движения искр
    } else {
    }
}

var alreadyrunflag = 0 //flag to indicate whether target function has already been run
if (isOldAndBuggy) {
} else {
    if (document.addEventListener)
        document.addEventListener("DOMContentLoaded", function () {
            alreadyrunflag = 1;
            iterate()
        }, false)
    else if (document.all && !window.opera) {
        document.write('<script type="text/javascript" id="contentloadtag" defer="defer" src="javascript:void(0)"><\/script>')
        var contentloadtag = document.getElementById("contentloadtag")
        contentloadtag.onreadystatechange = function () {
            if (this.readyState == "complete") {
                alreadyrunflag = 1
                iterate()
            }
        }
    }

    window.onload = function () {
        setTimeout(function () {
            if (!alreadyrunflag) iterate()
        }, 0)
    }
}

function setImage(id, imageNum) {
    //console.log("entered setImage");
    var cell = document.getElementById("dot" + id);
    var referId = "iskr" + imageNum + "-" + id;
    //console.log(referId);
    //console.log(cell.innerHTML);
    if (cell.hasChildNodes()) {
        for (var i = 0; i < cell.childNodes.length; i++) {
            if (referId == cell.childNodes[i].id && cell.childNodes[i].style.display != "block") {
                cell.childNodes[i].style.display = "block";
            } else {
                //console.log(cell.childNodes[i].style);
                if (cell.childNodes[i].style.display != "none") {
                    cell.childNodes[i].style.display = "none";
                }
            }
        }
    }
}
