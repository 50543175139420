import React from 'react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import './Stars.css'
import './Fonts/BAHNSCHRIFT.TTF'
import './Faded.css'
import './components/Cursor/Cursor.css'
import './components/Cursor/Cursor'
import './PlayerJinke.css'
import './EndStyle.css'
import SnackBarRedux from "./components/SnackBarRedux/SnackBarRedux";
import LoadingIndicatorRedux from "./components/LoadingIndicatorRedux/LoadingIndicatorRedux";
import DialogRedux from "./components/DialogRedux/DialogRedux";
import Main from "./containers/Main";


function App() {
    return (
        <>
            <div id='stars'/>
            <div id='stars2'/>
            <div id='stars' style={{position: "absolute", right: '35%'}}/>
            <div id='stars2' style={{position: "absolute", right: '35%'}}/>

            <Main/>
            <SnackBarRedux/>
            <LoadingIndicatorRedux/>
            <DialogRedux/>

            {/*<div id='stars3'/>*/}
        </>
    )
}

export default App
