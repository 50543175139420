import React, {useState} from "react";
import {Image} from "semantic-ui-react";
import anime from "animejs";

import photo_1 from "../../images/photo_lines/1.jpg";
import photo_2 from "../../images/photo_lines/2.jpg";
import photo_3 from "../../images/photo_lines/3.jpg";
import photo_4 from "../../images/photo_lines/4.jpg";
import photo_5 from "../../images/photo_lines/5.jpg";

const photos = [photo_1, photo_3, photo_2, photo_4, photo_5];

let photo_line_image_1_index = 1;
let photo_line_image_2_index = 2;
let photo_line_image_3_index = 3;
let photo_line_image_4_index = 4;
let repeating_state = false;

setTimeout(() => animate_function(), 8000);

setInterval(() => {
    animate_function()
}, 20000);

function animate_function() {
    anime.timeline({loop: false})
        .add({
            targets: `.photo_line_image_${repeating_state === false ? 1 : 2}`,
            opacity: 0,
            duration: 10000,
            easing: "easeOutExpo",
            delay: 0
        });
    anime.timeline({loop: false})
        .add({
            targets: `.photo_line_image_${repeating_state === false ? 3 : 4}`,
            opacity: 0,
            duration: 10000,
            easing: "easeOutExpo",
            delay: 0
        });

    setTimeout(() => {
            if (repeating_state === false) {
                photo_line_image_1_index = (photo_line_image_2_index + 1 > 4 ? 0 : photo_line_image_2_index + 1);
                photo_line_image_3_index = (photo_line_image_4_index + 1 > 4 ? 0 : photo_line_image_4_index + 1)
            } else {
                photo_line_image_2_index = (photo_line_image_1_index + 1 > 4 ? 0 : photo_line_image_1_index + 1);
                photo_line_image_4_index = (photo_line_image_3_index + 1 > 4 ? 0 : photo_line_image_3_index + 1)
            }
        }, 10500
    );

    anime.timeline({loop: false})
        .add({
            targets: `.photo_line_image_${repeating_state === false ? 2 : 1}`,
            opacity: [0, 1],
            easing: "easeInOutQuad",
            duration: 10000,
            delay: 0
        });
    anime.timeline({loop: false})
        .add({
            targets: `.photo_line_image_${repeating_state === false ? 4 : 3}`,
            opacity: [0, 1],
            easing: "easeInOutQuad",
            duration: 10000,
            delay: 0
        });
    repeating_state = !repeating_state;
    // console.log('interval')
}

export default function PhotoLine() {
    return <div style={{display: 'flex'}}>

            <div style={{position: 'relative'}}>

                <div className='photo_line_image_2 faded faded-left'
                     style={{position: 'absolute', color: 'rgb(0,0,0)'}}>
                    <div className='faded faded-left faded-top faded-bottom' style={{color: 'rgb(0,0,0)'}}>
                        <Image src={photos[photo_line_image_2_index]}/>
                    </div>
                </div>
                <div className='photo_line_image_1 faded faded-left' style={{color: 'rgb(0,0,0)'}}>
                    <div className='faded faded-left faded-top faded-bottom' style={{color: 'rgb(0,0,0)'}}>
                        <Image src={photos[photo_line_image_1_index]}/>
                    </div>
                </div>

            </div>
            <div style={{position: 'relative'}}>

                <div className='photo_line_image_4 faded faded-right'
                     style={{position: 'absolute', color: 'rgb(0,0,0)'}}>
                    <div className='faded faded-right faded-top faded-bottom' style={{color: 'rgb(0,0,0)'}}>
                        <Image src={photos[photo_line_image_4_index]}/>
                    </div>
                </div>
                <div className='photo_line_image_3 faded faded-right' style={{color: 'rgb(0,0,0)'}}>
                    <div className='faded faded-right faded-top faded-bottom' style={{color: 'rgb(0,0,0)'}}>
                        <Image src={photos[photo_line_image_3_index]}/>
                    </div>
                </div>

            </div>

    </div>
}
