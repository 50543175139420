import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import "react-image-lightbox/style.css";

import photo_1 from "../images/merch/1.jpg"
import photo_2 from "../images/merch/2.jpg"
import photo_3 from "../images/merch/3.jpg"
import photo_4 from "../images/merch/4.jpg"
import photo_5 from "../images/merch/5.jpg"
import photo_6 from "../images/merch/6.jpg"
import photo_7 from "../images/merch/7.jpg"
import photo_8 from "../images/merch/8.jpg"
import photo_9 from "../images/merch/9.jpg"
import photo_10 from "../images/merch/10.jpg"
import photo_11 from "../images/merch/11.jpg"
import photo_12 from "../images/merch/12.jpg"
import photo_13 from "../images/merch/13.jpg"
import photo_14 from "../images/merch/14.jpg"
import photo_15 from "../images/merch/15.jpg"
import photo_16 from "../images/merch/16.jpg"
import photo_17 from "../images/merch/17.jpg"


export const images = [
    {
        src: photo_1,
        original: photo_1,
        url: "https://vk.com/market-163364401?w=product-163364401_7995307%2Fquery",
        // tags: [
        //     { value: "IRRAKRA", title: "IRRAKRA" },
        // ],
        caption: "IRRAKRA",
    },
    {
        src: photo_2,
        original: photo_2,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995314%2Fquery"
    },
    {
        src: photo_3,
        original: photo_3,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995297%2Fquery"
    },
    {
        src: photo_4,
        original: photo_4,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995304%2Fquery"
    },
    {
        src: photo_5,
        original: photo_5,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995312%2Fquery"
    },
    {
        src: photo_6,
        original: photo_6,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995298%2Fquery"
    },
    {
        src: photo_7,
        original: photo_7,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995305%2Fquery"
    },
    {
        src: photo_8,
        original: photo_8,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7995318%2Fquery"
    },
    {
        src: photo_9,
        original: photo_9,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7243084%2Fquery"
    },
    {
        src: photo_10,
        original: photo_10,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7243080%2Fquery"
    },
    {
        src: photo_11,
        original: photo_11,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028414%2Fquery"
    },
    {
        src: photo_12,
        original: photo_12,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028398%2Fquery"
    },
    {
        src: photo_13,
        original: photo_13,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028383%2Fquery"
    },
    {
        src: photo_14,
        original: photo_14,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028375%2Fquery"
    },
    {
        src: photo_15,
        original: photo_15,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028362%2Fquery"
    },
    {
        src: photo_16,
        original: photo_16,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028352%2Fquery"
    },
    {
        src: photo_17,
        original: photo_17,
        caption: "IRRAKRA",
        url: "https://vk.com/market-163364401?w=product-163364401_7028327%2Fquery"
    }
];

export default function Merch() {
    return (
        <div>
            <Gallery
                images={images}
                onClick={(index, item)=> window.location.assign(item.url)}
                enableImageSelection={false}
            />
        </div>
    );
}
