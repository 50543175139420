import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import {useDispatch, useSelector} from "react-redux";

export default function SnackBarRedux() {
    const snackBarSuccessMessage = useSelector(state => state.snackBar.snackBarSuccessMessage);
    const snackBarErrorMessage = useSelector(state => state.snackBar.snackBarErrorMessage);
    const snackBarError = useSelector(state => state.snackBar.snackBarError);
    const snackBarSuccess = useSelector(state => state.snackBar.snackBarSuccess);
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <SnackbarAlert open={snackBarError} severity="error"
                           message={snackBarErrorMessage}
                           handleClose={() => dispatch({type: 'snackBar/snackBarCloseErrorMessage'})}/>
            <SnackbarAlert open={snackBarSuccess} autoHideDuration={3000}
                           handleClose={() => dispatch({type: 'snackBar/snackBarCloseSuccessMessage'})}
                           severity="success"
                           message={snackBarSuccessMessage}/>
        </React.Fragment>
    )
}


function SnackbarAlert(props) {
    function select_alert_to_show(severity, message) {
        switch (severity) {
            case 'success':
                return <div><Alert onClose={props.handleClose} severity="success">{message}</Alert></div>;
            case 'error':
                return <div><Alert onClose={props.handleClose} severity="error">{message}</Alert></div>;
            case 'warning':
                return <div><Alert onClose={props.handleClose} severity="warning">{message}</Alert></div>;
            case 'info':
                return <div><Alert onClose={props.handleClose} severity="info">{message}</Alert></div>
        }
    }

    return (
        <div>
            <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={props.handleClose}
                      style={{left: '43%'}}>
                {select_alert_to_show(props.severity, props.message)}
            </Snackbar>
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
