import React, {useState} from "react";
import IrrakraLabel from "../images/IrrakraLabel.png";
import {Image} from "semantic-ui-react";
import PopupWithLink from "../components/PopupWithLink/PopupWithLink";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import anime from 'animejs/lib/anime.es.js';
import {set_title_Redux} from "../redux/reduxMethods";

export default function Header() {
    const [fadeout_ref, setFadeout_ref] = useState();
    const [fadein_ref, setFadein_ref] = useState();

    //Анимации
    if (fadeout_ref) {
        anime.timeline({loop: false})
            .add({
                targets: `.${fadeout_ref.id}`,
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 0
            })
    }
    if (fadein_ref) {
        anime.timeline({loop: false})
            .add({
                targets: `.${fadein_ref.id}`,
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 2250,
                delay: 0
            })
    }
    //

    return <div style={{marginTop: "3%"}}>
        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
            <div style={{display: "flex", flexDirection: "column", userSelect: "none", marginLeft: 15}}>
                <div style={{fontSize: '26pt', marginBottom: 30, letterSpacing: "1.5pt"}}>IRRAKRA</div>
                <div className="PalatinoFont glitch" style={{fontSize: "14pt", width: 180}}
                     data-text='LED SHOW METAL'>LED SHOW METAL
                </div>
            </div>
            <div style={{width: 175, userSelect: "none"}}>
                <div><Image src={IrrakraLabel} className='spin_slowly'/></div>
                <div style={{marginTop: 8, position: "absolute", marginLeft: 51}}>
                    <div style={{display: "flex"}}>
                        <PopupWithLink icon_name={'vk'} link_to={'https://vk.com/irrakra'} popup_text={'Мы в VK'}/>
                        <PopupWithLink icon_name={'youtube'} link_to={'https://www.youtube.com/@irrakraband4735'}
                                       popup_text={'Мы на YouTube'}/>
                        <PopupWithLink custom_icon={<div><MusicNoteIcon className='cccColorLink'/></div>}
                                       link_to={'https://music.yandex.ru/artist/9217948'}
                                       popup_text={'Мы в Яндекс Музыке'}/>
                    </div>
                </div>
            </div>
        </div>

        <div style={{display: "flex", fontSize: 18, justifyContent: "center", userSelect: "none", minWidth: 600, marginTop: 50}}
             className='PalatinoFont'>
            <div style={{marginRight: 40, width: 55}}
                 id='menu_1'
                 className={`glitch_hover ${fadeout_ref?.id === 'menu_1' ? '' : 'cursor_pointer'} menu_1`}
                 onClick={(event) => {
                     setFadein_ref(fadeout_ref);
                     setFadeout_ref(event.target);
                     set_title_Redux('О нас')
                 }}
                 data-text="О нас"
            >
                О нас
            </div>
            <div style={{marginRight: 40, width: 160}}
                 id='menu_2'
                 className={`glitch_hover ${fadeout_ref?.id === 'menu_2' ? '' : 'cursor_pointer'} menu_2`}
                 onClick={(event) => {
                     setFadein_ref(fadeout_ref);
                     setFadeout_ref(event.target);
                     set_title_Redux('Музыка и тексты')
                 }}
                 data-text="Музыка и тексты">Музыка и тексты
            </div>
            <div style={{marginRight: 40, width: 125}}
                 id='menu_3'
                 className={`glitch_hover ${fadeout_ref?.id === 'menu_3' ? '' : 'cursor_pointer'} menu_3`}
                 onClick={(event) => {
                     setFadein_ref(fadeout_ref);
                     setFadeout_ref(event.target);
                     set_title_Redux('Фото и Видео')
                 }}
                 data-text="Фото и Видео">Фото и Видео
            </div>
            <div style={{marginRight: 40, width: 85}}
                 id='menu_4'
                 className={`glitch_hover ${fadeout_ref?.id === 'menu_4' ? '' : 'cursor_pointer'} menu_4`}
                 onClick={(event) => {
                     setFadein_ref(fadeout_ref);
                     setFadeout_ref(event.target);
                     set_title_Redux('Концерты')
                 }}
                 data-text="Концерты">Концерты
            </div>
            <div style={{width: 50}}
                 id='menu_5'
                 className={`glitch_hover ${fadeout_ref?.id === 'menu_5' ? '' : 'cursor_pointer'} menu_5`}
                 onClick={(event) => {
                     setFadein_ref(fadeout_ref);
                     setFadeout_ref(event.target);
                     set_title_Redux('Мерч')
                 }}
                 data-text="Мерч"
            >Мерч
            </div>
        </div>

    </div>
}
