import {
    close_dialog,
    loading_off,
    loading_on,
    set_title,
    show_dialog,
    snackBarErrorMessage,
    snackBarSuccessMessage,
    store
} from "./store";

export function show_snackBarErrorMessage(message) {
    store.dispatch(snackBarErrorMessage(message));
}

export function show_snackBarSuccessMessage(message) {
    store.dispatch(snackBarSuccessMessage(message));
}

export function show_dialog_Redux({
                                      dialog_title, custom_component, function_for_dialog = () => {
    }
                                  }) {
    store.dispatch(show_dialog({dialog_title, custom_component, function_for_dialog}));
}

export function close_dialog_Redux() {
    store.dispatch(close_dialog());
}

export function loading_on_Redux() {
    store.dispatch(loading_on());
}

export function loading_off_Redux() {
    store.dispatch(loading_off());
}

export function set_title_Redux(title) {
    store.dispatch(set_title(title))
}
