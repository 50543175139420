import {useEffect, useRef} from "react";

export function getRandomInt(max) { //  < max, но не равно
    return Math.floor(Math.random() * max);
}

export function useEffectAfterUpdate(dependentValue, callback = () => {
}) {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // toggle flag after first render/mounting
            return;
        }
        callback(); // performing action after state has updated
    }, dependentValue);
}

export function compare_dates(first_date, second_date, compare_type = '===') {
    switch (compare_type) {
        case '===':
            return first_date.getTime() === second_date.getTime();
        case '!==':
            return first_date.getTime() !== second_date.getTime();
        case '<=':
            return first_date.getTime() <= second_date.getTime();
        case '>=':
            return first_date.getTime() >= second_date.getTime();
        case '>':
            return first_date.getTime() > second_date.getTime();
        case '<':
            return first_date.getTime() < second_date.getTime();
    }
}

export function find_date_in_array(array, date_to_find) {
    return array.find((date) => compare_dates(date, date_to_find))
}

export function not_null_and_not_undefined_check(variable) {
    return variable !== null && variable !== undefined;
}

export function not_null_undefined_empty_check(variable) {
    return variable !== null && variable !== undefined && variable !== ""
}

export function fastest_iterator_array(length) {
    const a = Array(length);
    let b = 0;
    while (b < length) {
        a[b++] = b
    }
    return a
}

export function index_of_first_string_from_array_in_string(check_string, array_of_strings) {
    for (let i = 0; i < array_of_strings.length; i++) {
        if (check_string.toLowerCase().indexOf(array_of_strings[i].toLowerCase()) !== -1) {
            return i
        }
    }
    return undefined
}

export function count_of_symbol_in_string(symbol, string) {
    return string.split(symbol).length - 1
}

export function capitalize_first_symbol(string) {
    if (not_null_undefined_empty_check(string)) {
        return string.replace(/^[^a-zа-яё]*([a-zа-яё])/i, function (m) {
            return m.toUpperCase();
        });
    } else {
        return string;
    }
}

export function isUserUsingMobile() {
// User agent string method
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// Screen resolution method
    if (!isMobile) {
        let screenWidth = window.screen.width;
        let screenHeight = window.screen.height;
        isMobile = (screenWidth < 768 || screenHeight < 768);
    }
// Touch events method
    if (!isMobile) {
        isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    }
// CSS media queries method
    if (!isMobile) {
        let bodyElement = document.getElementsByTagName('body')[0];
        isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
    }
    return isMobile
}

export function rotate_crossbrowser(deg) {
    return {
        webkitTransform: `rotate(${deg}deg)`,
        mozTransform: `rotate(${deg}deg)`,
        msTransform: `rotate(${deg}deg)`,
        oTransform: `rotate(${deg}deg)`,
        transform: `rotate(${deg}deg)`
    }
}
